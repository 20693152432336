import React from 'react'
import { Icon } from '@iconify/react';
import Vimeo from '@u-wave/react-vimeo';

export default function Home() {

    return (
        <React.Fragment>
            <>           
            <div id="head">
                <div className='firefly'></div>
                <div className='firefly'></div>
                <div className='firefly'></div>
                <div className='firefly'></div>
                <div className='firefly'></div>
                <div className='firefly'></div>
                <div className='firefly'></div>
                <div className='firefly'></div>
                <div className='firefly'></div>
                <div className='firefly'></div>
                <div className='firefly'></div>
                <div className='firefly'></div>
                <div className='firefly'></div>
                <div className='firefly'></div>
                <div className='firefly'></div>
                <div className="nav">
                <input type="checkbox" id="nav-check" />
                    <div className="nav-btn ">
                        <label htmlFor="nav-check">
                        <span />
                        <span />
                        <span />
                        </label>
                        <div className="nav-social " style= {{ marginTop: "1vw" }}>
                            <a href="https://discord.gg/robococknft" target = "_blank" style= {{ marginRight: "4vw" }}><Icon className="icon" icon="simple-icons:discord" color="indianred" /></a>

                            <a href="https://twitter.com/RoboCockNFT" target = "_blank" style= {{ marginRight: "4vw" }}><Icon className="icon" icon="logos:twitter" color="indianred" /></a>
                            
                            <a href="https://magiceden.io/marketplace/robo_cocks" target = "_blank"><Icon className="icon" icon="healthicons:market-stall" color="#dc1fff" /></a>
                        </div>
                    </div>
                    <div className="nav-links">
                        <a href="#roadmap" className="icon-down scroll" data-speed={500}>
                        ROADMAP
                        </a>
                        <a href="#faq" className="icon-down scroll" data-speed={500}>
                        FAQ
                        </a>
                        <a href="#team" className="icon-down scroll" data-speed={500}>
                        TEAM
                        </a>
                        <a href="https://breed.robocock.io/" className="icon-down scroll" data-speed={500}>
                        Breeding
                        </a>
                        <a href="https://stake.robocock.io/" className="icon-down scroll" data-speed={500}>
                        STAKE
                        </a>
                        <a href="https://discord.gg/robococknft" target = "_blank"><Icon className="icon" icon="simple-icons:discord" color="indianred" width="3vw" height="3vw" /></a>

                        <a href="https://twitter.com/RoboCockNFT" target = "_blank"><Icon className="icon" icon="logos:twitter" color="indianred" width="3vw" height="3vw" /></a>

                        <a href="https://magiceden.io/marketplace/robo_cocks" target = "_blank"><Icon className="icon" icon="healthicons:market-stall" color="#dc1fff" width="3vw" height="3vw" /></a>

                    </div>
                </div>
            </div>

            {/* Video */}
            <div className="video_background">
                {/*
            <div id="load" className="fadeout">
                <div>Y</div>
                <div>D</div>
                <div>A</div>
                <div>E</div>
                <div>R</div>
                <div></div>
                <div>U</div>
                <div>O</div>
                <div>Y</div>
                <div></div>
                <div>E</div>
                <div>R</div>
                <div>A</div>
            </div>
                    */}
              
            <Vimeo
                video="https://vimeo.com/675359616?autoplay=1"
                autoplay
                responsive
                />
        
            </div>
                 
            {/* Lore */}
            
            <div className="lore_background scroll-element js-scroll fade-in-bottom " >
            <div id="particles-js">            </div>
                <div className="lore">
                
                <div className="inner">
                    <p>
                    In a violent, near-apocalyptic future of the #solana verse, legions of
                    monstrous robots known as mutant dragonz that arose from the void,
                    bringing with them all-consuming war. To fight the dragonz, mankind
                    developed giant robot suits called Robococks and RICE serums, designed
                    to be piloted by a sole human locked in a neural bridge to sync with
                    the mechanical suit.
                    <br />
                    <br />
                    However, even with the Robocock suits, its not enough and with
                    humanity on the verge of defeat, a new untested RoboCock prototype is
                    now launching. Will you heed the call and report in for duty?
                    <br />
                    <br />
                    Come forth, pilot and join in on the mechanical battle for the future,
                    because only then will you know what Robocock is about.
                    <br />
                    <br />
                    Bok Bok!
                    </p>
                </div>
                </div>

            </div>

            {/* GALLERY */}
            <div
                className="gallery_background scroll-element js-scroll slide-left"
                id="gallery"
            >
                <div className="gallery">Gallery</div>
                <div className="gallery_inner ">
                <img src="./assets/img/1.png"  alt="NFT 1"/>
                <img src="./assets/img/2.png"  alt="NFT 2"/>
                <img src="./assets/img/9.png"  alt="NFT 3"/>
                <img src="./assets/img/12.png"  alt="NFT 4"/>
                <img src="./assets/img/13.png"  alt="NFT 5"/>
                <img src="./assets/img/6.png"  alt="NFT 6"/>
                <img src="./assets/img/7.png"  alt="NFT 7"/>
                <img src="./assets/img/8.png"  alt="NFT 8"/>
                <img src="./assets/img/11.png"  alt="NFT 9"/>

                </div>

            </div>

            {/* ROADMAP */}
            <div
                className="roadmap_background scroll-element js-scroll fade-in"
                id="roadmap"
            ></div>

            {/* MarketPlace */}
            <div
                className="mp_background scroll-element js-scroll fade-in"
                id="mp"
            ></div>
            {/* FAQ */}
            <div
                className="faq_background scroll-element js-scroll fade-in-bottom"
                id="faq"
            >   
                    <div className="faq">FAQ</div>
                    <div className="faq_content">
                        <div id="question">Where can I buy Robo Cocks?</div>
                        <div id="answer">
                        We have sold out during our mint day on 22/02/2022.
                        Now you can purchase Robo Cocks on Magic Eden. <br></br>

                        OFFICIALLY, we are ONLY listed on <a href="https://www.magiceden.io/marketplace/robo_cocks/">Magic Eden</a>.
                        </div><br></br>
                        <div id="question">Where do I stake my Robo Cocks?</div>    
                        <div id="answer">
                        You can stake your cocks at:
                        <a href="https://stake.robocock.io/"> https://stake.robocock.io/</a>
                        <br></br>
                        Robo Cocks GEN 1 – 
                        Stake and get 5 $RICE per day.
                        <br></br>
                        Robo Cocks GEN 2 – 
                        Staking starts at 3 $RICE per day, 
                        Stake for 7 days and above to get 5 $RICE per day, 
                        Stake for 14 days and above to get 7 $RICE per day.
                        </div><br></br>
                        <div id="question">How to get an 3GG for Robococks GEN 2?</div>
                        <div id="answer">
                        2 factions, 1 Invidia + 1 Virtus with 600 $RICE get an 3GG. 
                        </div><br></br>
                        <div id="question">How long does it take for the 3GG to hatch?</div>
                        <div id="answer">
                        Auto hatch in 30 days or Fast hatch with additional 500 $RICE.
                        </div><br></br>
                        <div id="question">What is the future use for $RICE?</div>
                        <div id="answer">
                        We have games and a marketplace platform in the pipelines, which would both have aspects for $RICE’s use-case.
                        </div><br></br>
                        <br></br>
                        <div className="bottom">
                        Have further burning questions? <br></br>
                        Join us in our Discord and ask a team member or a mod!          
                        <br></br>
                        
                            Alternatively, feel free to reach out to us at{" "}
                            <a href="mailto:support@niore.io">support@niore.io</a>!
                        </div>
                    </div>

            </div>
            {/* TEAM */}
            <div
                className="team_background scroll-element js-scroll slide-right"
                id="team"
            >
                <div className="team">THE TEAM</div>
                <div className="team_mem">
                <div className="col-lg-4" id="team_col">
                    <img src="./assets/img/10.png" alt="Avatar" className="team_avatar" />
                    <h2 style={{ fontSize: "0.7vw", marginTop: "1vw", marginBottom: "0.5vw" }}>katy</h2>
                    <p style={{ fontSize: "0.7vw" }}>
                    Mastermind of the latest Robocock Prototype. Founder of Robo Tech
                    </p>
                </div>
                <div className="col-lg-4" id="team_col">
                    <img src="./assets/img/6.png" alt="Avatar" className="team_avatar" />
                    <h2 style={{ fontSize: "0.7vw", marginTop: "1vw", marginBottom: "0.5vw"  }}>aeson.niore</h2>
                    <p style={{ fontSize: "0.7vw" }}>
                    Mastermind behind Robocock concept
                    </p>
                </div>

                <div className="col-lg-4" id="team_col">
                    <img src="./assets/img/1.png" alt="Avatar" className="team_avatar" />
                    <h2 style={{ fontSize: "0.7vw", marginTop: "1vw", marginBottom: "0.5vw"  }}>NormyNormy</h2>
                    <p style={{ fontSize: "0.7vw" }}>
                    Mastermind behind Robocock concept
                    </p>
                </div>

                <div className="col-lg-4" id="team_col">
                <img src="./assets/img/2.png" alt="Avatar" className="team_avatar" />
                    <h2 style={{ fontSize: "0.7vw", marginTop: "1vw", marginBottom: "0.5vw"  }}>kakasonzxc</h2>
                    <p style={{ fontSize: "0.7vw" }}>
                    The legend behind the suit's capabilities and technical expertise
                    </p>
                </div>

                <div className="col-lg-4" id="team_col">
                    <img src="./assets/img/5.png" alt="Avatar" className="team_avatar" />                    
                    
                    <h2 style={{ fontSize: "0.7vw", marginTop: "1vw", marginBottom: "0.5vw"  }}>8 Eggs</h2>
                    <p style={{ fontSize: "0.7vw" }}>
                    Lead recruiter for the Robocock programme. Verified Drill Sergeant
                    </p>
                </div>

                <div className="col-lg-4" id="team_col">
                    <img src="./assets/img/3.png" alt="Avatar" className="team_avatar" />
                    <h2 style={{ fontSize: "0.7vw", marginTop: "1vw", marginBottom: "0.5vw"  }}>gambitking</h2>
                    <p style={{ fontSize: "0.7vw" }}>
                    Who says military programmes need no PR? The King of PR ensures all expectations are met
                    </p>
                </div>

                <div className="col-lg-4" id="team_col">
                    <img src="./assets/img/7.png" alt="Avatar" className="team_avatar" />
                    <h2 style={{ fontSize: "0.7vw", marginTop: "1vw", marginBottom: "0.5vw"  }}>3MOTIONS</h2>
                    <p style={{ fontSize: "0.7vw" }}>
                    Music Producer
                    </p>
                </div>

                </div>
            </div>
            {/* FOOTER */}
            <footer className="mt-auto text-white-50 footer">
                <p>NIORE TEAM | ROBOCOCK NFT</p>
            </footer>
            </>



        </React.Fragment>
    )
}