import logo from './logo.png'; 
import './App.css';

import Home from './pages/Home.js';

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
